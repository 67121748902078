import Vue from 'vue'
import Auth from '@okta/okta-vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

const CALLBACK_PATH = '/implicit/callback'

const config = {
  issuer: process.env.VUE_APP_OKTA_URL + '/oauth2/default',
  clientId: process.env.VUE_APP_OKTA_CLIENT_ID,
  redirectUri: process.env.VUE_APP_HOME_URL + CALLBACK_PATH,
  scope: 'openid profile email',
};

Vue.use(Auth, {...config})

Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: CALLBACK_PATH,
    component: Auth.handleCallback()
  },
  {
    path: '/6fc2381f-2b34-4eba-a709-ff46c945f56a',
    name: 'TM20FUG',
    component: () => import(/* webpackChunkName: "tm20fug" */ '../components/tm20fug.vue'),
  },
  {
    path: '/3badb7b2-aa00-43eb-b0df-1ac79fc60056',
    name: 'TM37FUG',
    component: () => import(/* webpackChunkName: "tm37fug" */ '../components/tm37fug.vue'),
  },
  {
    path: '/d02523ae-a202-4b03-8056-98e3d5214f26',
    name: 'TM09FUG',
    component: () => import(/* webpackChunkName: "tm09fug" */ '../components/tm09fug.vue'),
  },
  {
    path: '/tm20fug',
    name: 'TM20FUG',
    component: () => import(/* webpackChunkName: "tm20fug" */ '../components/tm20fug.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/tm37fug',
    name: 'TM37FUG',
    component: () => import(/* webpackChunkName: "tm37fug" */ '../components/tm37fug.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/tm09fug',
    name: 'TM09FUG',
    component: () => import(/* webpackChunkName: "tm09fug" */ '../components/tm09fug.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach(Vue.prototype.$auth.authRedirectGuard())

export default router
