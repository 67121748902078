<template>
  <v-app id="inspire" >
    <v-navigation-drawer v-model="drawer" :clipped="$vuetify.breakpoint.lgAndUp" class="hidden-print-only" app>
      <v-list dense>
          <template v-for="item in items">
            <v-row
              v-if="item.heading"
              :key="item.heading"
              align="center"
            >
              <v-col cols="6">
                <v-subheader v-if="item.heading">
                  {{ item.heading }}
                </v-subheader>
              </v-col>
              <v-col
                cols="6"
                class="text-center"
              >
                <a
                  href="#!"
                  class="body-2 black--text"
                >EDIT</a>
              </v-col>
            </v-row>
            <v-list-group
              v-else-if="item.children"
              :key="item.text"
              v-model="item.model"
              :prepend-icon="item.model ? item.icon : item['icon-alt']"
              append-icon=""
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.text }}
                  </v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item
                v-for="(child, i) in item.children"
                :key="i"
                link
                router :to="child.route"
              >
                <v-list-item-action v-if="child.icon">
                  <v-icon>{{ child.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ child.text }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
            <v-list-item
              v-else
              :key="item.text"
              link
              router :to="item.route"
            >
              <v-list-item-action>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.text }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
    </v-navigation-drawer>

    <v-app-bar
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      app
      color="blue darken-3"
      dark
      class="hidden-print-only"
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      
      <v-toolbar-title class="ml-0 pl-4">
        <span class="hidden-sm-and-down">Unde e mașina mea? | Monitorizare flotă auto</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn small color="primary" v-if='authenticated' v-on:click='logout'>Logout</v-btn>
      <v-btn small color="primary" v-else v-on:click='login'>Login</v-btn>
    </v-app-bar>
    <v-content>
      <v-container :key="$route.path" class="fluid">
        <!-- The router view displays the components as the user click router links -->
        <router-view/>
      </v-container>
      <!-- Footer -->
      <v-footer class="font-weight-medium">
        <v-col class="text-center hidden-print-only" cols="12">
          &copy;2010 - {{ new Date().getFullYear() }} | <strong>www.undeemasinamea.ro</strong> | Monitorizare flotă auto
        </v-col>
      </v-footer>
    </v-content>
  </v-app>
</template>

<script>
  export default {
    props: {
      source: String,
    },
    data: () => ({
      authenticated: false,
      drawer: false,
      items: [
        { icon: 'home', text: 'Acasă', route: '/' },
        {
          icon: 'mdi-chevron-up',
          'icon-alt': 'mdi-chevron-down',
          text: 'Vehicule',
          model: false,
          children: [
            { icon: 'directions_car', text: 'TM20FUG', route: '/tm20fug'},
            { icon: 'directions_car', text: 'TM37FUG', route: '/tm37fug'},
            { icon: 'directions_car', text: 'TM09FUG', route: '/tm09fug'},
          ],
        },
        { icon: 'contact_support', text: 'Despre', route: '/despre' }
      ],
    }),
    created () { this.isAuthenticated() },
    watch: {
    // Everytime the route changes, check for auth status
    '$route': 'isAuthenticated'
    },
    methods: {
      async isAuthenticated () {
        this.authenticated = await this.$auth.isAuthenticated()
      },
      login () {
        this.$auth.loginRedirect('/')
      },
      async logout () {
        await this.$auth.logout();
        await this.isAuthenticated();
        if (this.$route.path !== '/') this.$router.push({ path: '/' })
      }
    }
  }
</script>